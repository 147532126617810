$( document ).ready(function() {
	$('.modal-header .close').on('click', function (e) {
		$('.modal').hide();
		console.log('123');
	})
	$('.ico-arrow-back').on('click', function (e) {
		$('.modal').hide();
	})

	$('.open-maximum').on('click', function(e){
		$('.modal-maximum').show();
	})

	$('.open-drive').on('click', function(e){
		$('.modal-drive').show();
	})
});