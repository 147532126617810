$(document).ready(function () {
	$( window ).resize(function() {
		galleryInit();
	});
	galleryInit();
	complectationOpen();
	showMore();
});
function galleryInit() {
	var galleryHeight = $(".gallery").height(),
		galleryWidth = $(".gallery").width();
	$('.list-slider').fotorama({
		height: galleryHeight,
		width: galleryWidth,
		allowfullscreen: true,
		nav: false,
	});
}
function complectationOpen() {
	var complectation_open = $(".complectation-open");
	var list = $(".list-wrapper");

	complectation_open.click(function () {
		list.toggleClass("open");
		complectation_open.toggleClass("tern");
	})
}
function showMore() {
	$(".more-box .more").on("click" , function () {
		$(this).remove();
		$(".cars-list .d-none").each(function () {
			$(this).removeClass("d-none");
		})
	})
}