$( document ).ready(function() {
	function setCarBenefitsSlider() {
		var slider = $(".benefits_slider");
		var list = slider.find(".benefits_list");
		var collection = list.find(".benefit_card");
		var next_button = $(".benefits_container .next");
		var prev_button = $(".benefits_container .prev");
		var current_index = 0;
		var current_offset = 0;
		var count = collection.length;

		function updateCurrentOffset() {
			current_offset = $(collection.get(current_index)).position().left;
		}

		function updateInterface() {
			next_button.removeClass("disabled");
			prev_button.removeClass("disabled");
			if (isLast()) {
				next_button.addClass("disabled");
			}
			if (isFirst()) {
				prev_button.addClass("disabled");
			}
		}

		function isFirst() {
			return current_index == 0;
		}

		function isLast() {
			return current_index == (count - 1);
		}

		function next() {
			if (isLast()) return;
			current_index++;
			updateCurrentOffset();
			updateCurrentPosition();
			updateInterface();
		}

		function prev() {
			if (isFirst()) return;
			current_index--;
			updateCurrentOffset();
			updateCurrentPosition();
			updateInterface();
		}

		function updateCurrentPosition() {
			list.css({marginLeft: -current_offset});
		}

		function init() {
			updateCurrentOffset();
			updateCurrentPosition();
			if (count > 1) {
				next_button.removeClass("hidden");
				prev_button.removeClass("hidden");
				updateInterface();
				next_button.click(function () {
					next();
				});
				prev_button.click(function () {
					prev();
				});
				if ($.swiperight) {
					slider.swiperight(function () {
						prev();
					});
					slider.swipeleft(function () {
						next();
					});
				}
			}
		}

		if (slider.length > 0) {
			init();
		}
	}
	setCarBenefitsSlider();
});