$(document).ready(function () {
	$(window).resize(function () {
		galleryInit();
	});
	var equipmentLayout = $(".equipment-layout");
	equipmentLayout.find(".list").each(function () {
		var _this = $(this).find("li");
		if (_this.length > 7) {
			$(this).find(".list_control").removeClass("hidden");
			for (var i = 6; i < _this.length; i++) {
				_this.eq(i).addClass("hidden");
			}
		}
		$(this).find(".list_control").on("click", function () {
			$(this).parent().find("li").removeClass("hidden");
			$(this).remove();
		})
	});
});

function galleryInit() {
	var galleryHeight = $(".cars-card .gallery-block").height(),
		galleryWidth = $(".cars-card.gallery-block").width();
	console.log(galleryWidth);
	$('.real-photos .fotorama').fotorama({
		height: galleryHeight,
		width: galleryWidth,
		allowfullscreen: true,
		nav: 'thumbs',
	});
}