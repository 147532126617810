/**
 * Created by orb-it-dev25 on 01.09.2017.
 */
$('body').on('focus', '.form_fields_group_wrapper input', function(){
	$(this).parents(".form_field_item").find(".form_label_layout").addClass('td-form__input_not-empty');
});
$('body').on('blur', '.form_fields_group_wrapper input', function(){
	$(this).parents(".form_field_item").find(".form_label_layout").removeClass('td-form__input_not-empty');
	if(this.value){
		$(this).parents(".form_field_item").find(".form_label_layout").addClass('td-form__input_not-empty');
	}
});
$('body').on('change', '.form_fields_group_wrapper input', function(){
	if (this.value) {
		$(this).parents(".form_field_item").find(".form_label_layout").addClass('td-form__input_not-empty');
	}
});


$('body').on('focus', '#calc_results input', function(){
	$(this).parents(".field_wrapper").find(".label_wrapper").addClass('td-form__input_not-empty');
});
$('body').on('blur', '#calc_results input', function(){
	$(this).parents(".field_wrapper").find(".label_wrapper").removeClass('td-form__input_not-empty');
	if(this.value){
		$(this).parents(".field_wrapper").find(".label_wrapper").addClass('td-form__input_not-empty');
	}
});
$('body').on('change', '#calc_results input', function(){
	if (this.value) {
		$(this).parents(".field_wrapper").find(".label_wrapper").addClass('td-form__input_not-empty');
	}
});