$( document ).ready(function() {
	$( window ).resize(function() {
		if ($(this).width() > 1439){
			fotoramaResize(220,112);
		}
		if ($(this).width() < 1439){
			fotoramaResize(168.5,98);
		}
		if ($(this).width() < 1024){
			fotoramaResize(110,63);
		}
	});

	function fotoramaResize(width, height){
		$('.real-photos .fotorama').fotorama({
			thumbwidth:width,
			thumbheight: height
		});
	}
	fotoramaResize(220,112);
});